/*----------------------------------------------------
　フッター
----------------------------------------------------*/
.l-site-footer {
    position: relative;
}
.l-site-footer-contact {
    background-color: #fff;
    padding: 20px 0 30px;
}
.l-site-footer-contact-heading {
    margin-bottom: 8px;
    color: #0F294D;
    font-weight: bold;
    font-size: 18px;
    @media(min-width: 768px) {
        font-size: 20px;
    }
}
.l-site-footer-contact-company {
    color: #0F294D;
    font-size: 13px;
    @media(min-width: 768px) {
        font-size: 16px;
    }
}
.l-site-footer-contact-call {
    margin-bottom: 4px;
    .l-site-footer-contact-call-link {
        color: #0F294D;
        font-weight: bold;
        font-size: 20px;
        text-decoration: none;
        transition: .3s;
        @media(min-width: 768px) {
            font-size: 22px;
        }

        @media(min-width: 992px) {
            &:hover {
                transition: .3s;
                opacity: .7;
            }
        }
    }
}
.l-site-footer-contact-inqury {
    color: #0F294D;
    font-size: 12px;
    line-height: 1.25;
    @media(min-width: 768px) {
        font-size: 14px;
    }
}

.l-site-footer-navi {
    background-color: #00A6B5;
    padding: 16px 0 20px;
}
.l-site-footer-navi-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    @media(min-width: 992px) {
        flex-direction: row;
    }
}
.l-site-footer-navi-content-menu-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 16px;
    @media(min-width: 992px) {
        margin-bottom: 0;
    }
}
.l-site-footer-navi-content-menu-list-item {
    padding-right: 16px;
    // @media(min-width: 992px) {
    //     padding-right: 20px;
    // }
}
.l-site-footer-navi-content-menu-list-item-link {
    position: relative;
    padding-left: 12px;
    color: #fff;
    text-decoration: none;
    font-size: 10px;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 55%;
        transform: translateY(-50%);
        display: inline-block;
        background-image: url("../../img/share/icon/icon_arrow_right_small.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: .5em;
        height: 1em;
    }
    @media(min-width: 992px) {
        &:hover {
            transition: .3s;
            opacity: .7;
        }
    }
}
.l-site-footer-navi-content-copyright {
    color: #fff;
    font-size: 10px;
}


//トップへ戻る
.page-to-top  {
    position: absolute;
    right: 8px;
    top: 0;
    .page-to-top-link {
        display: inline-block;
        text-decoration: none;
        text-align: center;
        transition: .3s;
        cursor: pointer;
        &:hover {
            opacity: .7;
            transition: .3s;
        }
    }
    .page-to-top-link-icon {
        margin-bottom: 2px;
        text-align: center;
    }
    .page-to-top-link-text {
        font-size: 10px;
        font-weight: bold;
        color:#455873;
    }
}
