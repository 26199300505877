/*----------------------------------------------------
　リセットCSS
----------------------------------------------------*/
/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,*::before,*::after{box-sizing:border-box}*{margin:0}html,body{height:100%}body{line-height:1.5;-webkit-font-smoothing:antialiased}img,picture,video,canvas,svg{display:block;max-width:100%}input,button,textarea,select{font:inherit}p,h1,h2,h3,h4,h5,h6{overflow-wrap:break-word}#root,#__next{isolation:isolate}


//ベース
@import './baselayout/header.scss';
@import './baselayout/footer.scss';

//パーツ
@import './component/wrap.scss';
@import './component/form.scss';
@import './component/heading.scss';
@import './component/tab.scss';
@import './component/box.scss';
@import './component/button.scss';
@import './component/accordion.scss';
@import './component/table.scss';
@import './component/label.scss';
@import './component/list.scss';
@import './component/modal.scss';
@import './component/icon.scss';
@import './component/search.scss';
@import './component/card.scss';
@import './component/counter.scss';
@import './component/parts.scss';
@import './component/checkbox.scss';
@import './component/sticky.scss';
@import './component/marker.scss';
@import './component/view.scss';
@import './component/login.scss';
@import './component/carousel.scss';
@import './component/dialog.scss';
@import './component/animation.scss';
@import './component/loading.scss';
@import './component/freespace.scss';
@import './component/faq.scss';
@import './component/datepicker.scss';



/*----------------------------------------------------
　ページ全体
----------------------------------------------------*/
.l-page-content {
    background-color: #E6F5F6;
    padding-bottom: 80px;
}

/*----------------------------------------------------
　ページ上部：H1
----------------------------------------------------*/
.l-page-content-upper_navi {
    padding-top: 14px;
    padding-bottom: 12px;
    background-color: #00A6B5;
    @media(min-width: 992px) {
        padding-top: 21px;
        padding-bottom: 19px;
    }
}
.l-page-content-upper_navi-heading {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    @media(min-width: 992px) {
        font-size: 30px;
    }
}

