
.faq-list {
    .faq-list-item {
        border-bottom: 1px solid rgba(#0F294D, .1);
        &:first-child {
            border-top: 1px solid rgba(#0F294D, .1);
        }
        .faq-list-item-accordion {
            border-radius: 0;
            box-shadow: none;
             .faq-list-item-accordion-heading {
                 min-height: auto;
             }
        }
    }
    .faq-list-item-accordion-heading {
        position: relative;
        font-size: 16px;
        color: #0F294D;
        font-weight: bold;
        padding: 12px 40px;
        @media(min-width:992px) {
            font-size: 18px;
            padding: 20px 52px 20px;
        }
        &::before {
            content: "Q";
            position: absolute;
            top: 10px;
            left: 16px;
            font-size: 18px;
            font-weight: bold;
            @media(min-width:992px) {
                left: 24px;
                top: 18px;
            }
        }

        //開閉時
        &.Mui-expanded {
            .faq-list-item-accordion-heading-icon {
                &:before {
                    transition: .3s;
                    transform: rotate(0deg);
                }
            }
        }

        //mui 上書き
        .MuiAccordionSummary-content {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .faq-list-item-accordion-heading-icon {
        position: absolute;
        top: 24px;
        right: 8px;
        @media(min-width:992px) {
            top: 32px;
            right: 24px;
        }
        &:before {
            display: inline-block;
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 16px;
            height: 1px;
            background-color: #0169D8;
            transform: rotate(90deg);
            transition: .3s;
        }
        &:after {
            display: inline-block;
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 16px;
            height: 1px;
            background-color: #0169D8;
        }
    }



    .faq-list-item-accordion-detail {
        display: inline-block;
        position: relative;
        padding: 8px 40px 24px;
        color: #455873;
        font-size: 14px;
        @media(min-width:992px) {
            padding: 0 52px 24px;
            font-size: 16px;
        }

        &::before {
            content: "A";
            position: absolute;
            color: #E64437;
            left: 16px;
            font-size: 18px;
            font-weight: bold;
            @media(min-width:992px) {
                left: 24px;
            }
        }
    }
}
