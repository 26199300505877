//swiperを上書き
.swiper {
    padding-bottom: 38px !important;
    .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 280px;
        overflow: hidden;
        background-color: #eee;
    }
    
    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
    }

    //active時
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #1A3268;
    }
}
