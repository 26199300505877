/*----------------------------------------------------
　コンポーネント：テーブル
----------------------------------------------------*/
table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
    line-height: 1.4;
    word-break: break-word;
    color:#455873;
    @media (min-width: 992px) {
        font-size: 16px;
    }
    //文字色
    .color-red {
        color: #E64437;
    }

    &.table-small {
        tr {
            td, th {
                @media (min-width: 992px) {
                    padding: 12px;

                    &.padding-side-large {
                        padding-left: 24px;
                        padding-right: 24px;
                    }
                }
            }
        }
    }
    &.table-xsmall {
        tr {
            td, th {
                @media (min-width: 992px) {
                    padding: 11px 16px;
                    font-size: 15px;
                }

                &.padding-side-large {
                    padding-left: 12px;
                    padding-right: 12px;
                    @media (min-width: 992px) {
                        padding-left: 24px;
                        padding-right: 24px;
                    }
                }
            }
        }
    }
    &.table-small-top_bottom {
        tr {
            td, th {
                padding: 9px 8px;
                @media (min-width: 992px) {
                    padding: 12px 16px;
                }
            }
        }
    }
    tr {
        td, th {
            padding: 10px 8px;
            vertical-align: middle;
            line-height: 1.5;
            border: 1px solid #e2e4ea;
            color: #0F294D;
            font-size: 12px;
            @media (min-width: 992px) {
              padding: 16px 24px;
              font-size: 16px
            }
        }
        th {
            text-align: left;
            background-color: #F2F4F8;
            color: #455873;
            font-size: 14px;
            font-weight: normal;

            &.fz-16 {
                font-size: 16px;
                @media (min-width: 992px) {
                    font-size: 16px;
                }
            }

            &.fz-16-md{
                @media (min-width: 992px) {
                    font-size: 16px;
                }
            }
        }
    }

    &.table-responsive {
        .table-responsive-group {
            @media (max-width: 991px) {
                display: table;
                width: 100%;
            }
            th {
                padding-left: 20px;
                padding-right: 20px;
                @media (max-width: 991px) {
                    display: table-caption;
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
            td {
                @media (max-width: 991px) {
                    display: table-cell;
                }
                &.table-responsive-group-heading {
                    padding-left: 15px;
                    padding-right: 15px;
                    @media (max-width: 991px) {
                        width: 37%;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }
        }
        tr {
            td,th {
                @media (max-width: 991px) {
                    display: block;
                }
            }
        }
    }

    &.table-responsive-vertical {
        @media (min-width: 992px) {
            display: block;
            border-left: 1px solid #e2e4ea;
            border-top: 1px solid #e2e4ea;
        }
        tbody {
            @media (min-width: 992px) {
                display: flex;
            }
        }
        tr {
            @media (min-width: 992px) {
                flex: 1;
            }
        }
        th, td {
            @media (min-width: 992px) {
                display: block;
                width: auto;
                border: none;
                border-right: 1px solid #e2e4ea;
                border-bottom: 1px solid #e2e4ea;
            }
        }

        &.large {
            th, td {
                @media (min-width: 992px) {
                    padding: 11px 24px;
                }
            }
        }
    }
}
//テーブル背景色
.table-bgc-nevy {
    background-color: #00A6B5;
    color: #fff;
}
.table-bgc-light_gray {
    background-color: #F2F4F8;
    color: #455873;
}
.table-bgc-light_navy {
    background-color: #E1E8F4;
    color: #455873;
    border: 1px solid rgba(#0F294D, .1);
}
.table-bgc-light_red {
    background-color: #FEF5F1;
    color: #C7302F;
}

//オフセット
.table-offset-left {
    margin-left: -1.5em;
    width: calc(100% + 1.5em);
}

// クーポンシステムUIで使用
.table-rounded-heading {
    padding: 8px 16px;
    font-size: 12px;
    font-weight: bold;
    color: #0f294d;
    background-color: #e1e8f4;
    border: 1px solid rgba(#0f294d, 0.1);
    border-radius: 5px 5px 0 0;
}

.table-rounded-content {
    padding: 0 10px;
    border-right: 1px solid rgba(#0f294d, 0.1);
    border-bottom: 1px solid rgba(#0f294d, 0.1);
    border-left: 1px solid rgba(#0f294d, 0.1);
    border-radius: 0 0 5px 5px;

    // 以下テーブルのスタイルを上書き
    table {
        tr {
            &:nth-child(n + 2) {
                border-top: 1px dashed rgba(#0f294d, 0.1);
            }

            &:last-child {
                border-top: 1px solid rgba(#0f294d, 0.1);
            }
        }

        td,
        th {
            padding: 10px 6px;
            border: none;
        }

        th {
            font-size: 13px;
            font-weight: bold;
            background-color: transparent;

            // 内訳部分
            span {
                display: block;
                font-size: 11px;
                font-weight: normal;
            }
        }

        td {
            min-width: 80px;
            font-family: Roboto, sans-serif;
            font-size: 14px;
            font-weight: bold;
            text-align: right;
        }
    }
}