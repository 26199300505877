/*----------------------------------------------------
　コンポーネント：sticky-list
----------------------------------------------------*/
.sticky-top-list {
    .sticky-top-list-item {
        margin-bottom: 32px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
