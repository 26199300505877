/*----------------------------------------------------
　コンポーネント：タブ
----------------------------------------------------*/
//タブのステップパーツ
.step-tab {
    .step-tab-option-list {
        .MuiTabs-flexContainer {
            list-style-type: none;
            display: flex;
            width:100%;
            padding:0;
            margin:0;
            overflow:hidden;
            .step-tab-option-list-item {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                position:relative;
                padding-left: 16px;
                padding-right: 8px;
                height: 46px;
                background: #E2E4EA;
                color: #8592A6;
                vertical-align: middle;
                text-align: center;
                font-size: 10px;
                overflow: initial; //mui上書き
                z-index: 2;
                opacity: 1;
                @media(min-width: 992px) {
                    font-size: 14px;
                    height: 56px;
                }
        
                &:last-child {
                    padding-right: 1em;
                    z-index: 1;
                }
        
                &:last-child:before
                {
                    display: none;
                }

                &:last-child::after
                {
                    margin-right: 0;
                }
        
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    margin: auto;
                }
        
                &:before {
                    top:-10px;
                    bottom: -10px;
                    right:-1em;
                    border-style: solid;
                    border-color: transparent transparent transparent #fff;
                    border-width: 33px 0 33px 1em;
                    z-index: 10;
                    @media(min-width: 992px) {
                        border-width: 38px 0 38px 1em;
                    }
                }
                &:after {
                    top:-10px;
                    bottom: -10px;
                    right:-.8em;
                    border-style: solid;
                    border-color: transparent transparent transparent #E2E4EA;
                    border-width: 33px 0 33px 1em;
                    z-index: 10;
                    @media(min-width: 992px) {
                        border-width: 38px 0 38px 1em;
                    }
                }
        
        
                //active時
                &.Mui-selected {
                    background: #455873;
                    color: #fff;
                    font-weight: bold;
                    &:after {
                        border-color: transparent transparent transparent #455873;
                    }
                }
            }
        }
    }
}


//タブ：フライト
.tab-flight {
    background-color: #E1E8F4;

    .tab-flight-inner {
        .MuiBox-root {
            padding: 8px;
            @media(min-width: 992px) {
                padding: 12px;
            }
        }
    }
    .tab-flight-content {
        .tab-flight-content-list {
            .tab-flight-content-list-item {
                position: relative;
                padding: 13px 16px;
                border-bottom: 2px solid #00A6B5;
                background-color: #fff;
                font-size: 12px;
                font-weight: bold;
                opacity: 1;
                color: #00A6B5;
                &.Mui-selected {
                    border-bottom: 2px solid #00A6B5;
                    background-color: #00A6B5;
                    color: #fff;
                    &::after {
                        display: none;
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: 1px;
                    height: 1.5em;
                    background-color: #E2E4EA;
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }

                @media(min-width:992px) {
                    transition: .3s;
                    &:hover {
                        opacity: .8;
                        transition: .3s;
                    }
                }
            }
        }
        .tab-flight-content-detail {
            background-color: #fff;
            border-radius: 4px;
            padding: 24px 16px;
            min-height: 152px;
            @media(min-width: 992px) {
                padding-top: 20px;
                padding-bottom: 10px;
            }
        }
        .tab-flight-content-detail-list {
            @media(min-width: 992px) {
                display: flex;
            }
            .tab-flight-content-detail-list-item {
                position: relative;
                padding: 20px 0;
                border-bottom: 1px solid #E2E4EA;
                @media(min-width: 992px) {
                    padding: 20px 32px 20px 50px;
                    width: 50%;
                    border-bottom: none;
                    border-right: 1px solid #E2E4EA;
                }
                &:first-child {
                    padding-top: 0;
                    @media(min-width: 992px) {
                        padding-top: 14px;
                        padding-left: 24px;
                    }
                }
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                    @media(min-width: 992px) {
                        border-right: none;
                        padding-right: 15px;
                        padding-top: 14px;
                    }
                    &::after {
                        display: none;
                    }
                }
                &::after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    bottom: -8px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-image: url("../../img/share/icon/icon-flight-separate-triangle.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 25px;
                    height: 12px;
                    @media(min-width: 992px) {
                        width: 30px;
                        height: 15px;
                        top: 50%;
                        right: -17px;
                        left: inherit;
                        bottom: inherit;
                        transform: translateY(-50%) rotate(-90deg);
                    }
                }
            }
        }
    }
}

