.dialog {
    .dialog-content {
        .dialog-content-text {
            font-size: 14px;
            color: #000000;
        }
    }
    .dialog-action {
        padding: 8px 24px 16px;
        @media(min-width:992px) {
            padding: 8px 24px 24px;
        }
    }
}