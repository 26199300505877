.react-datepicker {
    .react-datepicker__header {
        background-color: #455873;
    }
    .react-datepicker__current-month {
        color: #fff;
    }
    .react-datepicker__day-name {
        color: #fff;
        font-weight: bold;
    }

    //左右ボタン
    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous {
        top: 7px;
    }
    .react-datepicker__navigation-icon::before {
        border-color:#fff;
    }

    .react-datepicker__triangle::after {
        border-bottom-color: #455873 !important;
    }
}