/*----------------------------------------------------
　コンポーネント：ラベル
----------------------------------------------------*/
.label-required {
    position: relative;
    bottom: 2px;
    background-color: #C7302F;
    border: 1px solid #C7302F;
    border-radius: 4px;
    color:#fff;
    font-size: 10px;
    padding: 2px 3px;
    line-height: 1;
    @media(min-width:992px){
        font-size: 12px;
        bottom: 3px;
    }
}

.label-navy {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #0F294D;
    border-radius: 4px;
    padding: 5px;
    color:#fff;
    font-size: 13px;
    line-height: 1;
    vertical-align: middle;
    @media(min-width:992px){
        font-size: 14px;
    }
}

.label-dark_orange {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #FF9800;
    border-radius: 4px;
    padding: 5px;
    color:#fff;
    font-size: 13px;
    line-height: 1;
    vertical-align: middle;
    @media(min-width:992px){
        font-size: 14px;
    }
}

.label-orange {
    background-color: #FFF1DB;
    color: #F87C00;
    padding: 4px 8px 3px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 4px;
    @media(min-width:992px){
        font-size: 12px;
    }
}

.label-gray-small {
    display: inline-block;
    background-color: #F2F4F8;
    color: #455873;
    padding: 3px 4px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 2px;
}


//ソーセージボタン
.label-light_blue-sausage {
    background-color: #00BCD4;
    color: #fff;
    padding: 4px 8px 3px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 100px;
    @media(min-width:992px){
        padding-left: 16px;
        padding-right: 16px;
        font-size: 14px;
    }
}

.label-orange-sausage {
    background-color: #E64437;
    color: #fff;
    padding: 4px 8px 3px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 100px;
    @media(min-width:992px){
        padding-left: 16px;
        padding-right: 16px;
        font-size: 14px;
    }
}

.label-gray-sausage {
    display: inline-block;
    background-color: #F2F4F8;
    color: #0F294D;
    padding: 4px 12px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 70px;
    @media(min-width:768px){
        font-size: 14px;
    }
}


//数字ラベル
.label-number-orange {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	padding: .2em;
	line-height: 1;
	border-radius: 50%;
	background-color: #FF9800;
	color: #fff;
}

.label-on_image-orange {
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    color: #D05313;
    background-color: #FED60E;
    padding: .3em;
    top: 0;
    left: 0;
    border-radius: 0 0 10% 0;
}
.label-travel-support {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 6px;
    color: #E64437;
    background-color: #FADE05;
    font-size: 13px;
    font-weight: bold;
    border-radius: 0 0 8px 0;
}