.freespace-wrap {
    .freespace-wrap-item {
        margin-bottom: 8px;
        @media(min-width:992px) {
            margin-bottom: 16px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}