//予約キャンセルページで使用
.login-form-card {
    padding: 32px 24px;

    //muiのスタイルを無効
    .MuiCardContent-root {
        padding: 0;
    }

    .login-form-card-inner {
        margin-bottom: 32px;
        .login-form-card-inner-item {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
            .login-form-card-inner-item-label {
                display: block;
                margin-bottom: 8px;
                font-size: 14px;
                font-weight: bold;
                color: #0F294D;
                font-weight: bold;
            }
            .login-form-card-inner-item-input {
                margin-top: 0;
            }
        }
    }

    .login-form-card-button {
        padding: 0;
    }
    .login-form-card-button-link {
        font-weight: bold;
        background-color: #F85400;
        @media(min-width: 992px) {
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 18px;
        }
        &:hover {
            background: rgba(#F85400,.7);
        }
    }
}
