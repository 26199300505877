/*----------------------------------------------------
　コンポーネント：カード
----------------------------------------------------*/
.card {
    padding: 32px 16px;
    line-height: 1.5;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 7px rgba(0,0,0,.1);
    @media(min-width: 992px) {
        padding: 32px;
    }
    &.sp-small {
        padding: 20px;
        @media(min-width: 992px) {
            padding: 40px;
        }
    }
    &.small {
        padding: 20px 16px 16px;
        @media(min-width: 992px) {
            padding: 20px 16px;
        }
    }
    &.medium {
        padding: 20px 16px 16px;
        @media(min-width: 992px) {
            padding: 24px 20px;
        }
    }
    &.large {
        padding: 24px 16px 32px;
        @media(min-width: 992px) {
            padding: 48px 48px 56px;
        }
    }
    &.large-side {
        padding: 8px 16px;
        @media(min-width: 992px) {
            padding: 24px 48px 24px;
        }
    }
    &.large-side-medium {
        padding: 24px 16px;
        @media(min-width: 992px) {
            padding: 33px 48px 33px;
        }
    }
}

.card-small {
    padding: 0;
    line-height: 1.5;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 7px rgba(0,0,0,.1);
    @media(min-width: 992px) {
        padding: 0;
    }
}

// 絞り込み検索用
.card-narrow-down {
    padding: 16px;
    line-height: 1.5;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, .1);

    @media(min-width: 992px) {
        padding: 20px;
    }

    // アコーディオンのスタイルを上書き
    .MuiPaper-elevation1 {
        box-shadow: none;
    }

    .MuiAccordionSummary-root {
        min-height: auto;
        &.Mui-expanded {
            min-height: auto;
        }
    }

    .MuiAccordionSummary-content {
        margin: 0;
        &.Mui-expanded {
            margin: 0;
        }
    }

    .MuiAccordionDetails-root {
        padding-top: 16px;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0;
    }

    //  トグルボタンのスタイルを上書き
    .MuiToggleButton-root {
        line-height: 1.7;
    }
}

.rentalcar-detail-select-content {
    .MuiAccordionDetails-root {
        padding-top: 0px;
        padding-right: 0;
        padding-bottom: 16px;
        padding-left: 0;
    }
}