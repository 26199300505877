/*----------------------------------------------------
　コンポーネント：細かいパーツ
----------------------------------------------------*/
.parts-room-list {
    .parts-room-list-item {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
        .parts-room-list-item-heading {
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: bold;
            @media (min-width: 992px) {
                margin-bottom: 32px;
                font-size: 24px;
            }
            
        }
    }
}
.parts-room-list-item-counter {
    .parts-room-list-item-counter-item {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(#0F294D,.1);
        @media (min-width: 992px) {
            padding-top: 24px;
            padding-bottom: 24px;
        }
        &:first-child {
            padding-top: 0;
            .parts-room-list-item-counter-item-content {
                top: 0;
            }
        }

        .parts-room-list-item-counter-item-content {
            position: absolute;
            top: 16px;
            right: 0;
            @media (min-width: 992px) {
                position: inherit;
            }
        }
    }
}
.parts-room-list-item-counter-item-heading {
    font-size: 16px;
    font-weight: bold;
    @media (min-width: 992px) {
        font-size: 18px;
    }

    .list-note {
        font-weight: normal;
        @media (min-width: 992px) {
            font-size: 13px;
        }
    }
}
.parts-room-list-item-counter-item-content-child {
    padding-top: 16px;
    width: 100%;
    color: #0F294D;
    @media (min-width: 992px) {
        padding-top: 24px;
    }
    .parts-room-list-item-counter-item-content-child-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid #fff;
        background-color: #E7F2FD;
        @media (min-width: 992px) {
            padding: 16px 24px;
        }
    }
}
.parts-room-list-item-counter-item-content-child-item-old {
    display: flex;
    align-items: center;
    .parts-room-list-item-counter-item-content-child-item-old-heading {
        font-size: 14px;
        font-weight: bold;
        margin-right: 16px;
        color: #0F294D;
        @media (min-width: 992px) {
            margin-right: 24px;
            font-size: 16px;
        }
    }
}


//
.part-group {
    .part-group-item {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// ホテルタグ用
.parts-hotel_tag-list{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .parts-hotel_tag-list-item{
        margin-right: 5px;
        margin-bottom: 5px;
        @media (min-width: 992px) {
            width: 74px;
        }

        img{
            width: 100%;
        }

        &:nth-of-type(5n){
            margin-right: 0;
        }
    }
}



//下固定ナビ
.parts-bottom-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding:0;
    background-color: #E6F5F6;
    z-index: 100;
    @media (min-width: 992px) {
        padding: 16px 24px 0;
    }
}


.parts-hover {
    display: inline-block;
    transition: .3s;
    &:hover{
        transition: .3s;
        opacity: .8;
    }

    &.w-100per {
        width: 100%;
        img {
            max-width: inherit;
            width: 100%;
        }
    }
}


.parts-inner-scroll-offset {
    margin-bottom: -100px;
}
.parts-inner-scroll-inset {
    padding-top: 130px !important;
}


//ページ内リンク一覧
.parts-loaclnavi-list {
    .parts-loaclnavi-list-item {
        border-bottom: 1px solid #E2E4EA;
        padding-top: 10px;
        padding-bottom: 10px;
        @media (min-width: 992px) {
            padding-top: 12px;
            padding-bottom: 12px;
        }
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
    .parts-loaclnavi-list-item-link {
        color: #0F294D;
        font-size: 14px;
        cursor: pointer;
        @media (min-width: 992px) {
            font-size: 16px;
            transition: .3s;
            &:hover {
                transition: .3s;
                opacity: .7;
            }
        }
    }
}