/*----------------------------------------------------
　コンポーネント：検索結果
----------------------------------------------------*/
.searchbox-wrap {
    box-shadow: 0 4px 6px rgba(0,0,0,.2);
    background-color: #29B0BC;
}
.search-result {
    .search-result-item {
        margin-bottom: 0;
    }
}
.search-result-item {
    display: flex;
    .search-result-item-dt {
        position: relative;
        padding-right: 8px;
        width: 93px;
        color: #455873;
        font-size: 13px;
        &::after {
            position: absolute;
            right: 0;
            content: "：";
        }

        &.small {
            width: 66px;
        }
    }

    .search-result-item-dd {
        font-size: 13px;
        font-weight: bold;
        color: #0F294D;

        //出発空港
        .search-result-item-dd-flight {
            display: flex;
            align-items: center;
            .search-result-item-dd-flight-item {
                position: relative;
                padding-right: 18px;
                @media (min-width: 992px) {
                    padding-right: 16px;
                }
                &::after {
                    content: "";
                    position: absolute;
                    right:5px;
                    top: 3px;
                    width: 10px;
                    height: 10px;
                    background-image: url("../../img/share/icon/icon_circle_arrow_right_black.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    @media (min-width: 992px) {
                        top: 2px;
                        right:2px;
                        width: 13px;
                        height: 13px;
                    }
                }

                &:last-child {
                    padding-right: 0;
                    &:after {
                        display: none;
                    }
                }
            }
        }

        //旅行期間
        .search-result-item-dd-date {
            display: flex;
            align-items: center;
            .search-result-item-dd-date-item {
                position: relative;
                padding-right: 16px;
                &::after {
                    content: "〜";
                    position: absolute;
                    right: 3px;
                    top: 2px;
                    width: 13px;
                    height: 13px;
                    @media (min-width: 992px) {
                        right: 2px;
                    }
                }

                &:last-child {
                    padding-right: 0;
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

//検索ボックスレイアウト
.searchbox-content {
    .searchbox-content-item {
        padding-top: 16px;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(#0F294D,.1);
        @media (min-width: 992px) {
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
        &:first-child  {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
        }

        &.border-bottom {
            border-bottom: 1px solid rgba(#0F294D,.1);
            @media (min-width: 992px) {
                border-bottom: none;
            }
        }

        &.border-bottom-none {
            border-bottom: none;
        }
    }
    &.last-border {
        .searchbox-content-item {
            &:last-child {
                border-bottom: 1px solid rgba(#0F294D,.1);
                @media (min-width: 992px) {
                    border-bottom: none;
                }
            }
        }
    }
}
.searchbox-content-item {
    &.column-60per {
        width: 100%;
        @media (min-width: 992px) {
            width: 60%;
        }
    }
    &.column-50per {
        width: 100%;
        @media (min-width: 992px) {
            width: 50%;
        }
        .searchbox-content-item-group-block-child-item {
            width: 100%;
            @media (min-width: 992px) {
                width: 50%;
            }
        }
    }
    &.column-40per {
        width: 100%;
        @media (min-width: 992px) {
            width: 40%;
        }
    }
    &.column-30per {
        width: 100%;
        @media (min-width: 992px) {
            width: 30%;
        }
    }
    &.column-20per {
        width: 100%;
        @media (min-width: 992px) {
            width: 20%;
        }
    }
}
.searchbox-content-item-group-block-child {
    display: flex;

    &.small {
        .searchbox-content-item-group-block-child-item {
            margin-right: 8px;
            @media (min-width: 992px) {
                margin-right: 16px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .searchbox-content-item-group-block-child-item {
        margin-right: 16px;
        &:last-child {
            margin-right: 0;
        }

        &.clumn-30per {
            width: 35%;
            @media (min-width: 992px) {
                width: 30%;
            }
        }
        &.clumn-70per {
            width: 65%;
            @media (min-width: 992px) {
                width: 70%;
            }
        }
    }
}


// 宿泊先 絞り込み用
.serchbox-stay {
    @media (min-width: 992px) {
        display: flex;
        align-items: center;
    }
}

.serchbox-stay-item {
    // 「条件で絞り込む」
    &:first-child {
        @media (min-width: 992px) {
            flex-basis: 140px;
        }
    }

    // 絞り込み条件項目
    &:last-child {
        @media (min-width: 992px) {
            flex-basis: calc(100% - 140px);
        }
    }
}