/*----------------------------------------------------
　コンポーネント：ラジオボタンで表示・非表示
----------------------------------------------------*/
.view-radio {
    // .view-radio-switch {}
    .view-radio-content {
        display: none;
    }
    input[type=radio]{
        &:checked {
            &.view-radio-active {
                color: red;
                &~.view-radio-content {
                    display: block;
                }
            }
            &~.view-radio-content {
                display: block;
            }
        }
    }

    &.border {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid #e2e4ea;
        @media (min-width: 992px) {
            margin-bottom: 32px;
            padding-bottom: 32px;
        }
    }
}