/*----------------------------------------------------
　幅
----------------------------------------------------*/
.wrap-content-350-sm {
    @media(min-width:992px) {
        margin: 0 auto;
        width: 100%;
        max-width: 350px;
    }
}
.wrap-content-530 {
    margin: 0 auto;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    max-width: 546px;
}

.wrap-content-900 {
    margin: 0 auto;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    max-width: 916px;

    //pc padding 40px
    &.wrap-padding-md {
        @media(min-width:992px) {
            padding-left: 40px;
            padding-right: 40px;
            max-width: 980px;
        }
    }
}

.wrap-content-900-large {
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    max-width: 932px;
    
    //pc padding 40px
    &.wrap-padding-md {
        @media(min-width:992px) {
            padding-left: 40px;
            padding-right: 40px;
            max-width: 980px;
        }
    }
}

.wrap-content-900-no_padding {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    max-width: 900px;
}