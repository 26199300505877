/*----------------------------------------------------
　ヘッダー
----------------------------------------------------*/
.l-site-header {
    display: flex;
    align-items: center;
    min-height: 60px;
    position: relative;
    z-index: 1;
    padding-top: 12px;
    padding-bottom: 14px;
    background-color: #fff;
    box-shadow: 2px 2px 7px rgba(0,0,0,.2);
    @media(min-width: 992px) {
        min-height: 64px;
        padding-top: 14px;
        padding-bottom: 12px;
    }
}
.l-site-header-list {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding-left: 0;
    @media(min-width: 992px) {
        padding-left: 2px;
    }
}
.l-site-header-list-item {
    padding-right: 14px;
    @media(min-width: 992px) {
        padding-right: 16px;
    }
}
.l-site-header-list-item-last-child {
    padding-right: 0;
}

.l-site-header-list-item.company {
    @media(min-width: 992px) {
        padding-right: 24px;
    }
    img {
        max-width: 150px;
        max-height: 34px;
        @media(min-width: 992px) {
            max-height: 38px;
        }
    }
}
.l-site-header-list-item.text {
    padding-right: 14px;
    font-size: 10px;
    color: #7A879C;
    @media(min-width: 992px) {
        font-size: 11px;
    }
}
.l-site-header-list-item.platform img {
    max-width: 150px;
    max-height: 40px;
    @media(min-width: 992px) {
        max-height: 50px;
    }
}