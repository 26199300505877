/*----------------------------------------------------
ステップアイコン用
----------------------------------------------------*/
//入力フォーム用
.form-step {
    margin: 0 auto 20px;
    padding: 20px 16px 0;
    max-width: 752px;
    position: relative;
    @media(min-width: 992px) {
        margin: 0 auto 20px;
        padding: 40px 16px 20px;
    }
}
.form-step:before {
    content:"";
    position: absolute;
    top: 45%;
    right: 30px;
    left: 30px;
    height: 3px;
    background-color: #A9B4C6;
    /* z-index: -1; */
    @media(min-width: 992px) {
        top: 42%;
    }
}

.form-step-list {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    list-style-type: none;
}
.form-step-list-item {
    text-align: center;
    color: #C7D6E8;
    font-size: 10px;
    @media(min-width: 992px) {
        font-size: 14px;
    }
}

.form-step-list-item-label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    background-color: #A9B4C6;
    font-size: 17px;
    line-height: 1;
    font-weight: bold;
    text-align: center;
    @media(min-width: 992px) {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
}

/* ステップラベルがactive時 */
.form-step-list-item.active {
    color: #0F294D;
    font-weight: bold;
}
.form-step-list-item.active .form-step-list-item-label {
    background-color: #FF9800;
    width: 40px;
    height: 40px;
    margin: 5px auto;
    @media(min-width: 992px) {
        width: 50px;
        height: 50px;
    }
}


//オプション選択画面用
.step-option {
    padding-bottom: 20px;
    @media(min-width: 992px) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    &.no-padding {
        padding-top: 0;
        padding-bottom: 0;
        @media(min-width: 992px) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
.step-option-list {
    list-style-type: none;
    display: flex;
    width:100%;
    padding:0;
    margin:0;
    overflow:hidden;
    .step-option-list-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        position:relative;
        padding-left: 16px;
        padding-right: 8px;
        height: 46px;
        background: #D3EFF0;
        color: #8592A6;
        vertical-align: middle;
        text-align: center;
        font-size: 10px;
        @media(min-width: 992px) {
            font-size: 14px;
            height: 56px;
        }

        &:last-child {
            padding-right: 1em;
        }

        &:last-child:before
       {
            display: none;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin: auto;
        }

        &:before {
            top:-10px;
            bottom: -10px;
            right:-1.1em;
            border-style: solid;
            border-color: transparent transparent transparent #fff;
            border-width: 33px 0 33px 1em;
            z-index: 10;
            @media(min-width: 992px) {
                border-width: 38px 0 38px 1em;
            }
        }
        &:after {
            top:-10px;
            bottom: -10px;
            right:-.9em;
            border-style: solid;
            border-color: transparent transparent transparent #D3EFF0;
            border-width: 33px 0 33px 1em;
            z-index: 10;
            @media(min-width: 992px) {
                border-width: 38px 0 38px 1em;
            }
        }


        //active時
        &.active {
            background: #00A6B5;
            color: #fff;
            font-weight: bold;
            &:after {
                border-color: transparent transparent transparent #00A6B5;
            }
        }
    }
}

/*----------------------------------------------------
　コンポーネント：フォーム
----------------------------------------------------*/
// デフォルトスタイルのリセット
// iOSでのデフォルトスタイルをリセット
input[type=submit],
input[type=reset],
input[type=button],
input[type=checkbox],
button {
    // font-family: $font_family;
    -webkit-appearance: button;
    appearance: button;
    cursor: pointer;
    &::-webkit-search-decoration {
        display: none;
    }
    &:focus {
        outline: none;
    }
}

input,textarea {
    // font-family: $font_family;
    -webkit-appearance: none;
}

select {
    // font-family: $font_family;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
}

label {
    cursor: pointer;
}

//IE用
select::-ms-expand {
    display: none;
}


//各フォーム
.form-input-text {
    width: 100%;
    height: 45px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid rgba(#0F294D,.1);
    border-radius: 5px;
    background-color: #F2F4F8;
    color: #0F294D;
    margin-bottom: 8px;
    &~.form-error_text {
        display: none;
    }
    &.error {
        background-color: #FEF5F1;
        border: 1px solid #E64437;
        //フォーム必須項目未入力時
        &~.form-error_text {
            display: block;
            margin-top: 5px;
            color: #E64437;
            font-weight: bold;
            font-size: 12px;
        }
    }

    &.small {
        width: 100px;
    }

    &.bold-navy {
        height: 40px;
        padding: 6px 13px;
        font-weight: bold;
        color: #0F294D;
    }

    //margin-bottom:0
    &.mb-none {
        margin-bottom: 0;
    }

    //placeholder
    &::placeholder {
        color: #0F294D;
        opacity: .3;
    }
}
.form-input-date {
    position: relative;
    width: 100%;
    padding: 8px 30px 8px 10px;
    font-size: 16px;
    border: 1px solid rgba(#0F294D,.1);
    border-radius: 5px;
    background-color: #F2F4F8;
    color: #0F294D;
    font-weight: bold;
    &.fw-n {
        font-weight: normal;
    }
    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 1em;
        height: 1em;
        background-image: url("../../img/share/icon/icon-calendar.svg");
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
    }
    &.datepicker {
       cursor: pointer;
    }

    &~.form-error_text {
        display: none;
    }
    &.error {
        background-color: #FEF5F1;
        border: 1px solid #E64437;
        //フォーム必須項目未入力時
        &~.form-error_text {
            display: block;
            margin-top: 5px;
            color: #E64437;
            font-weight: bold;
            font-size: 12px;
        }
    }
    //placeholder
    &::placeholder {
        color: #0F294D;
        opacity: .3;
        font-weight: bold;
    }
}
.form-input-date-icon-wrap {
    position: relative;
    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 1em;
        height: 1em;
        background-image: url("../../img/share/icon/icon-calendar.svg");
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
}

.form-date-seal {
    position: relative;
    .form-input-date {
        text-indent:calc(100% + 30px);
        white-space:nowrap;
        overflow:hidden;
        padding: 6px 30px 6px 10px;
    }
    .form-date-seal-label {
        position: absolute;
        top: 6px;
        left: 10px;
        right: 30px;
        bottom: 6px;
        color: #0F294D;
        font-weight: bold;
        font-size: 16px;
        line-height: 1.75;
        background-color: #f2f4f8;
    }
}

input[type="time"] {
    &.form-input-date::-webkit-calendar-picker-indicator {
        opacity: 0;
        position: absolute;
        right:8px;
    }
}
input[type="date"] {
    &.form-input-date::-webkit-calendar-picker-indicator {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 2;
    }
}

//iosで中央寄せになるので対策
input[type=date]::-webkit-date-and-time-value {
    text-align: left;
}

//セレクトボックス デフォルト
.select_box-default {
	position:relative;
	&:after {
		content: "";
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		background-image: url("../../img/share/icon/icon_arrow_down_medium.svg");
		background-repeat: no-repeat;
		background-size: contain;
		width: 13px;
		height: 7px;
		pointer-events: none;
	}
    
	select {
		appearance: none;
		width: 100%;
		padding: 8px 30px 8px 10px;
		background-color: #F2F4F8;
		color: #0F294D;
		border: 1px solid rgba(#0F294D,.1);
		border-radius: 5px;
		font-size: 16px;
	}

    &::-webkit-calendar-picker-indicator {
        display: none;
      }
    

	//選択不可
	&.disabled {
		&::after {
			background-image: url("../../img/share/icon/icon_arrow_down_medium.svg");
		}

		select {
			color: #0F294D;
			&:disabled {
				opacity: 1;
			}
			&:hover {
				cursor: text;
			}
		}
	}

    //ボタン
    .select_box-default-button {
        width: 100%;
        border-radius: 4px;
        padding: 9px 9px 10px;
        border: 1px solid rgba(#0F294D,.1);
        background-color: #F2F4F8;
        color: #0F294D;
        text-align: left;
    }
}

//テキストエリア
.form-input-textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid rgba(#0F294D,.1);
    border-radius: 5px;
    background-color: #F2F4F8;
    color: #0F294D;
    &~.form-error_text {
        display: none;
    }
    &.error {
        background-color: #FEF5F1;
        border: 1px solid #E64437;
        //フォーム必須項目未入力時
        &~.form-error_text {
            display: block;
            margin-top: 5px;
            color: #E64437;
            font-weight: bold;
            font-size: 12px;
        }
    }
}

//  ラジオボタン
input[type=radio] {
    display: none;
    &+label {
        display: inline-block;
        position: relative;
        padding-left: 28px;
        min-height: 22px;
        vertical-align: middle;
        line-height: normal;
        color: #0F294D;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 2;
        transition: .3s;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 2px;
            left: 0;
            width: 20px;
            height: 20px;
            border: 1px solid rgba(#0F294D,.1);
            background: #F2F4F8;
            border-radius: 50%;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 7px;
            left: 5px;
            width: 10px;
            height: 10px;
            background: #fff;
            border-radius: 50%;
        }

        &:hover {
            @media(min-width:992px) {
                transition: .3s;
                color: #0F294D;
            }

            p {
                @media(min-width:992px) {
                    color: #0F294D;
                }
                span {
                    @media(min-width:992px) {
                        color: #0F294D;
                    }
                }
            }
        }
    }
    &:checked {
        &+label {
            color: #0F294D;
            &:before {
                content: "";
                background: #0059b9;
            }
            &:after {
                content: "";
            }

            p {
                @media(min-width:992px) {
                    color: #0F294D;
                }
                span {
                    @media(min-width:992px) {
                        color: #0F294D;
                    }
                }
            }
        }
    }
    //無効時
    &:disabled {
        &+label {
            &:after {
                display: none;
            }
        }
    }
}

//生年月日
.form-birthday {
    display: flex;
    flex-wrap: wrap;
    .form-birthday-year,
    .form-birthday-month,
    .form-birthday-day
    {
        display: flex;
        align-items: center;
        .form-input-text {
            margin-bottom: 0;
        }
    }
    .form-error_text {
        display: none;
    }

    //エラー時
    &.error {
        .form-birthday-year {
            .form-input-text {
                border: 1px solid #E64437;
                color: #E64437;
            }
        }
        .form-birthday-month{
            .select_box-default select {
                border: 1px solid #E64437;
                color: #E64437;
            }
        }
        .form-birthday-day{
            .select_box-default select {
                border: 1px solid #E64437;
                color: #E64437;
            }
        }
        .form-error_text {
            display: block;
            width: 100%;
            margin-top: 5px;
            color: #E64437;
            font-weight: bold;
            font-size: 12px;
        }
    }
}

//メール
.form-column-mail {
    @media(min-width: 992px) {
        display: flex;
    }
    .form-column-mail-item {
        margin-bottom: 16px;
        @media(min-width: 992px) {
            margin-bottom: 0;
            margin-right: 16px;
            width: 50%;
            &:last-child {
                margin-right: 0;
            }
        }

        &.small {
            width: 50%;
            @media(min-width: 992px) {
                width: 30%;
            }
        }

        &.large {
            width: 100%;
            @media(min-width: 992px) {
                width: 70%;
            }
        }
    }
}

//住所
.form-column-address {
    display: flex;
    .form-column-address-item {
        margin-right: 10px;
        width: 40%;
        @media(min-width: 992px) {
            width: 30%;
        }
    }
    .form-column-mail-item {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
}



//pcのみ横並び
.form-column-pc {
    @media(min-width: 992px) {
        display: flex;
    }
    .form-column-pc-item {
        margin-bottom: 16px;
        @media(min-width: 992px) {
            margin-bottom: 0;
            margin-right: 16px;
            width: 50%;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

//フォーム横並び
.form-content {
    @media(min-width: 992px) {
        display: flex;
        align-items: center;
    }
}
.form-content-heading {
    margin-bottom: 16px;
    @media(min-width: 992px) {
        margin-bottom: 0;
        width: 250px;
    }

    &.small {
        @media(min-width: 992px) {
            width: 200px;
        } 
    }
}
.form-content-input {
    @media(min-width: 992px) {
        flex: 1;
    }

    .form-error_text {
        display: none;
    }
    &.error {
        .select_box-default select {
            border: 1px solid #E64437;
            background-color: #FEF5F1;
            color: #E64437;
        }
        .form-error_text {
            display: block;
            width: 100%;
            margin-top: 5px;
            color: #E64437;
            font-weight: bold;
            font-size: 12px;
        }
    }
}

.form-content-input-sexual {
    color: #455873;
    .form-error_text {
        display: none;
    }
    &.error {
        input[type=radio] + label {
            // color: #E64437;
            &:before {
                background-color: #FEF5F1;
                border: 1px solid #E64437;
                
            }
        }
        .form-error_text {
            display: block;
            width: 100%;
            margin-top: 5px;
            color: #E64437;
            font-weight: bold;
            font-size: 12px;
        }
    }
}


//旅行代表者
.form-content-input-representative {
    margin-top: 16px;
    margin-bottom: 30px;
}
.form-content-input-representative-list {
    &>li {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

//フォームボーダーあり
.box-separator_bottom-large {
    .box-separator_bottom-large-item {
        border-bottom: 1px solid rgba(#0F294D,.1);
        padding-top: 24px;
        padding-bottom: 24px;
        @media(min-width: 992px) {
            padding-top: 32px;
            padding-bottom: 32px;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}


//フォーム：チェックボックス
.form-input-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
}
.form-input-checkbox-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .form-input-checkbox-text {
        padding-left: 8px;
        flex: 1;
        color: #455873;
    }
}
.form-input-checkbox-icon {
    position: relative;
    width: 1em;
    height: 1em;
    margin-right: 8px;
    vertical-align: middle;
    cursor: pointer;

    //デフォルトアイコン非表示
    visibility: hidden;
    //IEのみ
    @media all and (-ms-high-contrast: none) {
        visibility: visible;
    }


    &:before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        height: 1.2em;
        width: 1.2em;
        background-color: #f2f4f8;
        border: 1px solid #e2e4ea;

        //独自アイコン表示
        visibility: visible;
    }
    &:after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: 1.2em;
        height: 1.2em;
        background-color: #f2f4f8;
        border: 1px solid #e2e4ea;
        border-radius: 5px;
        cursor: pointer;

        //独自アイコン表示
        visibility: visible;
    }
    &:checked {
        &:before {
            content: '';
            position: absolute;
            top: 4px;
            left: 2px;
            border: 3px solid #e2e4ea;
            border-top-style: none;
            border-right-style: none;
            width: .7em;
            height: .3em;
            transform: rotate(-45deg) scale(1,1);
            z-index: 1;
            background-color: #0169d8;
        }
        &:after {
            content: '';
            background-color: #0169d8;
            border: 1px solid #e2e4ea;
        }
    }
}
//firefoxのみ
_:lang(x)::-moz-placeholder,.form-input-checkbox-icon {
    visibility: visible;
}
.form-input-checkbox-text {
    display: inline-block;
    text-align: left;
    font-size: 13px;
    color: #0F294D;
    @media(min-width:992px){
        font-size: 16px;
    }

    &.text-large {
        font-size: 16px;
        font-weight: bold;
        @media(min-width:992px){
            font-size: 18px;
        }
    }

    &.c-blue_gray {
        color: #455873;
    }
}


//フォーム同行者
.form-accompany {
    .form-accompany-item {
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .form-accompany-item-heading {
        padding: 12px 16px;
        background-color: #E1E8F4;
        color: #0F294D;
        font-size: 16px;
        border: 1px solid rgba(#0F294D,.1);
        border-radius: 5px 5px 0 0;
    }
    .form-accompany-item-content {
        padding: 24px 16px;
        border-bottom: 1px solid rgba(#0F294D,.1);
        border-left: 1px solid rgba(#0F294D,.1);
        border-right: 1px solid rgba(#0F294D,.1);
        border-radius: 0 0 5px 5px;
    }
}


// フォーム　次のステップボタン
// .form-step_button {}
.form-step_button-back {
    text-align: center;
}
.form-step_button-next {
    width: 100%;
    margin-bottom: 24px;
    a {
        display: block;
    }
}



/*----------------------------------------------------
セレクトボックス（リストメニュー）
----------------------------------------------------*/
.form-select_list {
    &.MuiList-padding {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.form-select_list-inner {
    &.MuiListItem-root {
        padding-top: 0;
        padding-bottom: 0;
    }
    &.MuiListItem-gutters {
        padding-left: 0;
        padding-right: 0;
    }
}

.form-select_list-selected_text {
    height: 40px;
    padding: 7px 13px;
    border: 1px solid rgba(#0F294D, 0.1);
    background: #F2F4F8;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > p {
        line-height: 1.25;
    }

    &.text_medium {
        & > p {
            font-size: 16px;
            line-height: 1.25;
            @media(min-width:992px){
                font-size: 16px;
            }
        }
    }

    &::after {
        content: "";
        flex: 0 0 auto;
        display: block;
        width: 14px;
        height: 1em;
        margin-left: 16px;
        background: url('../../img/share/icon/icon_arrow_down_medium.svg') center center / contain no-repeat;
    }

    &.MuiListItemText-root {
        margin-top: 0;
        margin-bottom: 0;
    }

    & > p {
        font-size: 14px;
        font-weight: bold;
        color: #0F294D;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media(min-width:375px){
            font-size: 16px;
        }
        @media(min-width:992px){
            font-size: 18px;
        }
    }
    
}

.form-select_list-options-item {
    &.MuiMenuItem-root {
        font-size: 14px;
        font-weight: bold;
        white-space: normal;
        @media(min-width:992px){
            font-size: 16px;
        }
    }
    &.MuiListItem-root.Mui-selected {
        background-color: #fff;
    }
}

.form-select_list-text {
    padding: 8px 0;
    font-size: 14px;
    font-weight: bold;
    color: #0F294D;
    @media(min-width:375px){
        font-size: 16px;
    }
    @media(min-width:992px){
        font-size: 18px;
    }
}