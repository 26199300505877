.box-service-insurance-freespace-item {
  margin-top: 56px;
}

.box-service-insurance-freespace-item {

  @media(min-width:992px) {
    padding: 24px 28px 24px;
  }
  padding: 16px 16px 16px;
}