/*----------------------------------------------------
　コンポーネント：チェックボックス
----------------------------------------------------*/
.checkbox-group {
    .checkbox-group-item {
        margin-right: 24px;
        &:last-child {
            margin-right: 0;
        }
    }
}