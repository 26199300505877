
//-------------------------------------------------
//　アニメーション： フェード
//-------------------------------------------------
.animation-fade {
    z-index: -99;
    transition: .2s;
    height: 0;
    opacity: 0;
    overflow: hidden;
    &.active {
        z-index: inherit;
        transition: .2s;
        height: auto;
        opacity: 1;
    }

    &.slow {
        transition: .3s;
        &.active {
            transition: .3s;
        }
    }

    &.h-100vh {
        &.active {
            min-height: 100vh;
        }
    }
}